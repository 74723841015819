<template>
  <div>
    <div class="horiz">
      <div class="vert hundret">

        <!-- ARTIKEL -->
        <div
          :class="($vuetify.breakpoint.smAndDown) ? 'vert biggap' : 'horiz biggap'"
          :style="($vuetify.breakpoint.smAndDown) ? '' : 'padding: 0 30px'"
        >
          <!-- Linke Seite: Eintragung -->
          <div
            class="vert-block admin-edit-create"
            :style="($vuetify.breakpoint.smAndDown) ? 'width: 100%' : ''"
          >
            <!-- Bearbeiten/Hinufügen Buttons -->
            <div class="horiz gap admin-btn-container">
              <v-btn
                dark
                outlined
                :disabled="is_create_mode"
                color="white"
                :style="(!is_create_mode) ? 'background-color: #1976D2' : 'background-color: transparent'"
                @click="is_create_mode = false"
              >
                Bearbeiten
              </v-btn>
              <v-btn
                dark
                outlined
                color="white"
                :style="(is_create_mode) ? 'background-color: #1976D2' : 'background-color: transparent'"
                @click="discardArticleClicked()"
              >
                Neuer Artikel
              </v-btn>
            </div>

            <!-- Eingabefelder -->
            <v-text-field
              label="Titel"
              background-color="#242424"
              dark
              v-model="article_title"
            />
            <v-select
              v-model="article_category"
              :items="categories"
              item-text="name"
              item-value="id"
              label="Kategorie"
              return-object
              background-color="#242424"
              dark
            />
            <div class="horiz gap">
              <v-select
                label="Autoren"
                v-model="article_authors"
                :items="potential_authors"
                item-text="name"
                item-value="id"
                :menu-props="{ maxHeight: '300' }"
                return-object
                background-color="#242424"
                multiple
                chips
                dark
              />
            </div>
            <div
              :class="($vuetify.breakpoint.smAndDown) ? 'vert admin-thumbnail gap' : 'horiz admin-thumbnail gap'"
            >
              <ImageSelect
                @selectImage="selectImage($event.value)"
              />
              <Thumbnail
                :title="article_title"
                :publish_date="getArticleDate(current_article)"
                :authors="article_authors"
                :style="'align-self: center'"
                :thumbnail="thumbnail"
                :reference="{}"
              />
            </div>

            <!-- Abbrechen/Löschen, Hinzufügen/Änderungen speichern -->
            <div class="horiz gap admin-btn-container">
              <v-btn
                v-if="!is_create_mode"
                color="red darken-1"
                outlined
                @click="showDeleteArticleOverlay(article_category.id, current_article.id)"
              >
                <v-icon
                  dark
                  small
                  left
                >
                  mdi-alert
                </v-icon>
                Löschen
              </v-btn>
              <v-btn
                color="red darken-1"
                outlined
                @click="discardArticleClicked();"
              >
                Abbrechen
              </v-btn>

              <v-btn
                v-if="is_create_mode"
                dark
                outlined
                @click="createArticle()"
              >
                Hinzufügen
              </v-btn>
              <v-btn
                v-else
                dark
                outlined
                @click="updateArticle()"
              >
                Änderungen speichern
              </v-btn>
            </div>
            <p class="hint" v-if="is_show_hint">
              Ein Artikel braucht immer einen Titel und eine Kategorie!
            </p>
            <p class="hint" v-if="is_show_img_hint">
              Nutze .jpg, .jpeg oder .png als Extension-Typ!
            </p>
            <p class="hint" v-if="is_show_publish_hint">
              Die zugehörige Kategorie ist noch nicht veröffentlicht!
            </p>
          </div>

          <!-- Rechte Seite: Auswählen -->
          <div
            class="vert-block hundret admin-list"
            :style="($vuetify.breakpoint.smAndDown) ? 'width: 100%; margin: 0;' : ''"
          >
            <!-- Kategorie Dropdown -->
            <div class="horiz gap">
              <v-select
                class="admin-table-dropdown"
                v-model="article_table_category"
                clearable
                :items="categories"
                item-text="name"
                item-value="id"
                label="Kategorie"
                return-object
                background-color="#242424"
                dark
                @change="article_category = article_table_category ? article_table_category : article_category"
              />
              <!-- move order_index up -->
              <v-btn
                dark
                icon
                outlined
                :disabled="is_create_mode || !article_table_category"
                @click="moveArticle(true, current_article)"
              >
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-arrow-up-bold
                    </v-icon>
                  </template>
                  <span>nach Vorne ziehen</span>
                </v-tooltip>
              </v-btn>

              <!-- move order_index down -->
              <v-btn
                dark
                icon
                outlined
                :disabled="is_create_mode || !article_table_category"
                @click="moveArticle(false, current_article)"
              >
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-arrow-down-bold
                    </v-icon>
                  </template>
                  <span>nach Hinten schieben</span>
                </v-tooltip>
              </v-btn>

              <!-- go to article page -->
              <v-btn
                dark
                icon
                outlined
                :disabled="is_create_mode"
                :href="getArticleHref(article_category.slug, current_article.id, true)"
                @click.left.prevent="navigateToArticle(article_category.slug, current_article.id, true)"
              >
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>zur Seite</span>
                </v-tooltip>
              </v-btn>

              <!-- publish/hide -->
              <v-btn
                dark
                icon
                outlined
                :disabled="is_create_mode"
                @click="showPublishArticleOverlay(current_article)"
              >
                <v-tooltip
                  bottom
                  v-if="current_article.published == 'public'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-close
                    </v-icon>
                  </template>
                  <span>verstecken</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  v-else
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-check
                    </v-icon>
                  </template>
                  <span>publizieren</span>
                </v-tooltip>
              </v-btn>
            </div>

            <v-text-field
              label="Suche"
              class="admin-table-search"
              v-model="search_term"
              clearable
              background-color="#242424"
              dark
            />

            <!-- Eigentliche Tabelle -->
            <v-data-table
              :headers="article_headers"
              :items="articles"
              :items-per-page="10"
              class="elevation-1"
              dark
              single-select
              @click:row="setCurrentArticle"
            >
              <!-- Move -->
              <template v-slot:item.move="{ item }">
                <div
                  class="vert"
                  v-if="article_table_category"
                >
                  <div
                    @click="moveArticle(true, item)"
                    class="btn-active-up"
                  >
                    <MenuUp
                      class="admin-move-btn move-up"
                    />
                  </div>
                  <div
                    @click="moveArticle(false, item)"
                    class="btn-active-down"
                  >
                    <MenuDown
                      class="admin-move-btn move-down"
                    />
                  </div>
                </div>
                <div
                  class="vert"
                  v-else
                >
                  <div>
                    <MenuUp
                      class="admin-move-btn-disabled btn-disabled move-up"
                    />
                  </div>
                  <div>
                    <MenuDown
                      class="admin-move-btn-disabled btn-disabled move-down"
                    />
                  </div>
                </div>
              </template>

              <!-- Publish -->
              <template v-slot:item.published="{ item }">
                <v-btn
                  dark
                  icon
                  @click="showPublishArticleOverlay(item)"
                >
                  <div v-if="item.published == 'public' || item.published == 'pro-user'" style="position: relative;">
                    <v-icon>
                      mdi-check
                    </v-icon>
                    <p v-if="item.published == 'pro-user'" style="position: absolute; bottom: -4px; right: 0px;">
                      +
                    </p>
                  </div>
                  <v-icon v-else>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>

              <!-- Publish Date -->
              <template v-slot:item.publish_date="{ item }">
                {{ getPublishedDate(item) }}
              </template>

              <!-- Updated Date -->
              <template v-slot:item.updated="{ item }">
                {{ getUpdatedDate(item) }}
              </template>

              <!-- Edit -->
              <template v-slot:item.edit="{ item }">
                <v-btn
                  dark
                  icon
                  :href="getArticleHref(item.category_slug, item.id, true)"
                  @click.left.prevent="navigateToArticle(item.category_slug, item.id, true)"
                >
                  <v-icon
                    color="blue darken-1"
                  >
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
    <WarningOverlay
      v-if="is_show_warning_overlay"
      :warning_text="warning.text"
      :warning_icon="warning.icon"
      @cancel="resetWarning()"
      @confirm="confirmWarning()"
    />
  </div>
</template>

<script>
import Thumbnail from '@/components/Thumbnail.component.vue';
import ImageSelect from '@/components/ImageSelect.component.vue';
import MenuUp from '@/components/SVG/MenuUp.component.vue';
import MenuDown from '@/components/SVG/MenuDown.component.vue';
import { ArticleDate } from '@/mixins/ArticleDate.mixin.js';
import { Ultra } from '@/mixins/Ultra.mixin.js';
import { Knowledge } from '@/mixins/Knowledge.mixin.js';
import { Navigation } from '@/mixins/Navigation.mixin.js';
import { ImageSelectMixin } from '@/mixins/ImageSelectMixin.mixin.js';
import { WarningOverlayMixin } from '@/mixins/WarningOverlayMixin.mixin.js';

export default {
  components: {
    Thumbnail,
    ImageSelect,
    MenuUp,
    MenuDown,
  },

  mixins: [
    ArticleDate, Ultra, Knowledge,
    Navigation, ImageSelectMixin, WarningOverlayMixin,
  ],

  props: [
    'categories',
    'potential_authors',
  ],

  computed: {
    articles() {
      let ret = this.getUObjects(
        {
          type: 'articles',
          ids: (this.article_table_category) ? this.article_table_category.articles : '',
        }
      );
      if(this.search_term) {
        ret = ret.filter(a => a.title.toLowerCase().includes(this.search_term.toLowerCase()));
      }

      return ret;
    },

    thumbnail() {
      if(this.new_image.local_url){
        return this.new_image.local_url;
      }
      if(this.current_article.featured_image) {
        return this.current_article.featured_image;
      }

      return 'https://takting-images.s3.us-east-2.amazonaws.com/articles/default-thumbnail.png';
    },

    is_article_changed() {
      let is_changed =
        this.current_article.title != this.article_title ||
        this.current_article.category_id != this.article_category.id ||
        JSON.stringify(
          this.getMatchedSimplePotentialAuthors(this.current_article.authors))
          .localeCompare(JSON.stringify(this.article_authors)
        ) != 0 ||
        this.new_image.local_url
      ;
      return is_changed && !this.is_create_mode;
    },
  },

  data: () => ({
    search_term: '',

    is_create_mode: true,
    current_article: { },
    article_title: '',
    article_category: '',
    article_authors: '',
    article_thumbnail: '',
    article_table_category: '',

    article_headers: [
      // { text: 'Index', value: 'order_index', width: '100px' },
      { text: '', value: 'move', width: '30px' },
      { text: 'Titel', value: 'title' },
      { text: 'Geändert am', value: 'updated', width: '120px' },
      { text: 'Sichtbar seit', value: 'publish_date', width: '120px' },
      { text: 'Publiziert', value: 'published', width: '103px', align: 'end'  },
      { text: '', value: 'edit', width: '30px', align: 'end'  },
    ],

    is_show_hint: false,
    is_show_publish_hint: false,
  }),

  methods: {
    createArticle() {
      if(this.article_title && this.article_category) {
        let u_object = {};
        u_object.title = this.article_title.trim();
        u_object.order_index = this.article_category.articles.length;

        if (this.article_authors.length) {
          u_object['add#authors'] = [];
          for (var i = 0; i < this.article_authors.length; i++) {
            u_object['add#authors'].push(this.article_authors[i].user_id);
          }
        }

        this.createUObject({
          url: [
            { categories: this.article_category.id },
            { articles: '' },
            { showWithEntitiesNormalized: '', }
          ],
          u_object: u_object,
        }).then((resp) => {
          new Promise((resolve) => {
            if(this.new_image.local_url) { // if a thumbnail was set
              this.uploadImage({
                url: [
                  { categories: this.article_category.id },
                  { articles: resp },
                ],
                url_suffix: '/imageupload?show=showWithEntitiesNormalized',
                image: this.new_image.file,
                update: { key: 'featured_image', value_suffix: 'articles[0].featured_image' },
              }).then(() => { this.resetImageSelection(); resolve(); })
            } else {
              resolve();
            }
          }).then(() => {
            this.resetCurrentArticle();
          })
        });
      } else {
        this.is_show_hint = true;
      }
    },

    updateArticle() {
        if(this.article_title && this.article_category) {
          // we need the category currently in use for our current_article
          let current_category = this.categories.find(category => category.articles.includes(this.current_article.id));

          // we only want to update, if there are actual updates on current_article
          let update = {};
          if(this.current_article.title != this.article_title.trim()) {
            update.title = this.article_title.trim();
          }

          let curr_a_ids = this.current_article.authors.map(author => author.user_id);
          let edit_a_ids = this.article_authors.map(author => author.user_id);
          if(JSON.stringify(curr_a_ids).localeCompare(JSON.stringify(edit_a_ids)) != 0) {
            let new_authors = [];
            let removed_authors = [];
            let i = 0;
            let j = 0;
            for(; i < curr_a_ids.length && j < edit_a_ids.length; i++, j++) {
              if(curr_a_ids[i] != edit_a_ids[j]) {
                if(!curr_a_ids.includes(edit_a_ids[j]) || removed_authors.includes(edit_a_ids[j])) { // if edit_a_ids[j] is a new author or has been removed previously
                  // we need to discard all following authors and add them in as in edit_a_ids[j] and following
                  for(; i < curr_a_ids.length; i++) {
                    removed_authors.push(curr_a_ids[i]);
                  }
                  for(; j < edit_a_ids.length; j++) {
                    new_authors.push(edit_a_ids[j]);
                  }
                  // loop breaks because i and j are at their max
                }
                else { // the author is in curr_a_ids and in edit_a_ids
                  // we need to remove all authors between the last matching author and curr_a_ids[i] == edit_a_ids[j]
                  for(; i < curr_a_ids.length; i++) {
                    if(curr_a_ids[i] != edit_a_ids[j]) {
                      removed_authors.push(curr_a_ids[i]);
                    }
                    else {
                      break;
                    }
                  }
                }
              }
            }
            if(j < edit_a_ids.length && i >= curr_a_ids.length) { // we haven't looped over all values of edit_a_ids but over all values of curr_a_ids
              for(; j < edit_a_ids.length; j++) {
                new_authors.push(edit_a_ids[j]); // we want to add every remaining value of edit_a_ids in this case
              }
            }
            if(i < curr_a_ids.length && j >= edit_a_ids.length) { // we haven't looped over all values of curr_a_ids but over all values of edit_a_ids
              for(; i < curr_a_ids.length; i++) {
                removed_authors.push(curr_a_ids[i]); // we want to remove every remaining value of curr_a_ids in this case
              }
            }

            let authors_to_remove = this.current_article.authors.filter(author => removed_authors.includes(author.user_id));
            let remove_authors_user_article_ids = authors_to_remove.map(author => author.id);

            let authors = {};
            if(new_authors.length > 0) {
              authors = { ...authors, ...{ ['add#authors']: new_authors, } };
            }
            if(remove_authors_user_article_ids.length > 0) {
              authors = { ...authors, ...{ ['remove#authors']: remove_authors_user_article_ids, } };
            }
            update = { ...update, ...authors };
          }

          new Promise((resolve) => {
            if(Object.keys(update).length > 0) { // if there are any updates
              this.updateUObject({ // update and then resolve
                url: [
                  { categories: current_category.id, },
                  { articles: this.current_article.id, },
                ],
                update: update,
              }).then(() => { resolve(); })
            } else {
              resolve(); // if not: instantly resolve
            }
          }).then(() => {
            new Promise((resolve) => {
              if(this.new_image.local_url) { // if a new thumbnail was set
                this.uploadImage({
                    url: [
                      { categories: this.article_category.id },
                      { articles: this.current_article.id },
                    ],
                    url_suffix: '/imageupload?show=showWithEntitiesNormalized',
                    image: this.new_image.file,
                    update: { key: 'featured_image', value_suffix: 'articles[0].featured_image' },
                }).then(() => { this.resetImageSelection(); resolve(); })
              } else {
                resolve();
              }
            }).then(() => {
              if(current_category.id != this.article_category.id) { // if the category got changed
                this.updateUObjectParent({ // update category
                  url: [
                    { categories: current_category.id, },
                    { articles: this.current_article.id, },
                    { showWithEntitiesNormalized: '' },
                  ],
                  update: {
                    parent_id: this.article_category.id,
                  },
                })
              }
            }).then(() => {
              this.requestUObject({
                url: [
                  { categories: current_category.id, },
                  { articles: this.current_article.id, },
                  { showWithEntitiesNormalized: '' },
                ],
              });
            })
          })
        }
        else {
          this.is_show_hint = true;
        }
      },

    deleteArticle(category_id, article_id) {
      this.deleteUObject({
        url: [
          { categories: category_id },
          { articles: article_id },
        ],
      }).then(() => {
          this.resetCurrentArticle();
        }
      )
    },

    moveArticle(is_up, art) {
      let index = this.articles.findIndex((element) => element.id === art.id);
      if((index <= 0 && is_up) || (index >= this.articles.length - 1) && !is_up) {
        return;
      }
      let new_order_index = (is_up) ? index - 1 : index + 1;
      let cat = this.categories.find(category => category.articles.includes(art.id));

      this.updateUObjectOrderIndex({
        url: [
          { categories: cat.id },
          { articles: art.id },
        ],
        update: { order_index: new_order_index },
      });
    },

    publishArticle(art) {
      let art_cat = this.categories.find(category => category.articles.includes(art.id));

      let pub;
      if(art.published == 'draft') {
        if(art_cat.published == 'draft') {
          this.is_show_publish_hint = true;
          return;
        }
        if(art_cat.published == 'pro-user') {
          pub = 'pro-user';
        }
        else {
          pub = 'public'
        }
      }
      else {
        pub = 'draft';
      }

      this.updateUObject({
        url: [
          { categories: art_cat.id, },
          { articles: art.id, },
        ],
        update: { published: pub },
      });
    },

    // gets called when article row item in data-table is clicked
    setCurrentArticle(article, row) {
      this.current_article = article;
      row.select(true);

      this.article_title = article.title;
      this.article_authors = this.getMatchedSimplePotentialAuthors(article.authors);
      this.article_thumbnail = article.thumbnail;
      if(this.article_table_category) {
        this.article_category = this.article_table_category;
      }
      else {
        this.article_category = this.categories.find(category => category.id == article.category_id);
      }
      this.new_image.local_url = '';

      this.is_create_mode = false;
      this.is_show_hint = false;
      this.is_show_publish_hint = false;
    },

    resetCurrentArticle() {
      this.is_create_mode = true;
      this.is_show_hint = false;
      this.is_show_publish_hint = false;

      this.resetImageSelection();

      this.current_article = { };
      this.article_title = '';
      this.article_category = '';
      this.article_authors = [];
      this.article_thumbnail = '';
      this.new_image.local_url = '';
    },

    discardArticleClicked() {
      if(this.is_article_changed) {
        this.showDiscardArticleChangesOverlay();
      }
      else {
        this.resetCurrentArticle();
      }
    },
  },
}
</script>

<style lang="css" scoped>
.v-btn {
  letter-spacing: normal;
  text-transform: none;
}
.vert {
  display: flex;
  flex-direction: column;
}
.vert-block {
  display: block;
  flex-direction: column;
}
.horiz {
  display: flex;
  flex-direction: row;
}
.hundret {
  width: 100%;
}
.gap {
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}
.biggap {
  grid-row-gap: 30px;
  grid-column-gap: 30px;
}
.giantgap {
  grid-row-gap: 50px;
  grid-column-gap: 50px;
}
.admin-head {
  cursor: pointer;
}
.admin-edit-create {
  width: 450px;
  flex-shrink: 0;
}
.admin-btn-container {
  justify-content: flex-end;
  flex-wrap: wrap;
}
.admin-list {
  margin: 0 20px;
  flex-grow: 1;
}
.admin-thumbnail {
  justify-content: space-between;
  padding: 4px 0 24px 0;
}
.admin-table-dropdown {
  padding-top: 0;
  margin-top: 0;
  max-width: 300px;
}
.admin-table-search {
  padding-top: 0;
  margin-top: 0;
  max-width: 485px;
}
h1 {
  padding-bottom: 20px;
}
.admin-move-btn, .admin-move-btn-disabled {
  height: 5px;
}
.btn-disabled {
  opacity: .33;
}
.btn-active-up, .btn-active-down {
  opacity: .67;
}
.btn-active-up:hover, .btn-active-down:hover {
  opacity: 1;
  cursor: pointer;
}
.move-up {
  transform: translateY(100%)
}
.move-down {
  transform: translateY(-100%)
}
</style>
